import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import DonateForm from "../components/DonateForm"

const Donate = () => {
  return (
    <Layout pageName="Donate">
      <SEO title="Donate" />
      <div className="bg-sec py-5">
        <Container fluid="md">
          <DonateForm />
        </Container>
      </div>
    </Layout>
  )
}

export default Donate
