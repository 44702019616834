import React, { useReducer, useCallback } from "react"
import PropTypes from "prop-types"
import {
  Form,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  Button,
  InputGroup,
} from "react-bootstrap"

import { countries } from "../data"

const causes = ["Program Operations", "Specific Fellow Project"]
const presetAmounts = [2000, 5000, 10000]
const panRegex = "^[A-Z]{5}[0-9]{4}[A-Z]{1}$"

const initialState = {
  currency: "INR",
  amount: 2000,
  isIndianCitizen: "Yes",
  address: "",
  country: "India",
  cause: "Program Operations",
  claimNoPassport: false,
  fellow: "",
  pan: "",
  passportNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  referralCode: "",
  company: "",
  city: "",
  state: "",
  postalCode: "",
  phone: "",
  terms: false,
}

const config = {
  minimumAmount: 100,
  currencySymbol: "₹",
}

function donateFormReducer(state, action) {
  let field = action.field,
    value = action.value
  switch (field) {
    case "pan": {
      value = value.toUpperCase()
    }
  }
  return { ...state, [field]: value }
}
function formErrorReducer(state, action) {
  const { type, field, errorMessage } = action
  switch (type) {
    case "setError": {
      return { ...state, [field]: errorMessage }
    }
    case "clearError": {
      const t = { ...state }
      delete t[field]
      return t
    }
  }
}

function checkRequired(value) {
  if (value === "" || value == null) {
    return false
  } else {
    return true
  }
}

function validateField(field, value, state) {
  const { currencySymbol, minimumAmount } = config
  const requiredFields = [
    "amount",
    "cause",
    "isIndianCitizen",
    "firstName",
    "email",
    "address",
    "city",
    "state",
    "country",
    "postalCode",
    "phone",
  ]
  let result = { errors: false, errorMessage: "" }
  let requiredError = { errors: true, errorMessage: "This field is required" }
  if (requiredFields.includes(field) && !checkRequired(value)) {
    return requiredError
  }
  switch (field) {
    case "amount": {
      if (value < minimumAmount) {
        return {
          errors: true,
          errorMessage: `The minimum amount is ${currencySymbol}${minimumAmount}`,
        }
      }
      break
    }
    case "fellow": {
      if (state.cause === "Specific Fellow Project" && !checkRequired(value)) {
        return requiredError
      }
      break
    }
    case "pan": {
      if (state.isIndianCitizen === "Yes") {
        if (!checkRequired(value)) {
          return requiredError
        }
        if (!new RegExp(panRegex).test(value)) {
          return {
            errors: true,
            errorMessage: "Please enter a valid PAN number",
          }
        }
      }
      break
    }
    case "claimNoPassport":
    case "passportNumber": {
      if (
        state.isIndianCitizen === "No" &&
        !checkRequired(value) &&
        state.claimNoPassport === false
      ) {
        return {
          errors: true,
          errorMessage:
            "A passport number is required. If you don't have a passport, select 'I don't have a passport'",
        }
      }
      break
    }
    case "terms": {
      if (value === false) {
        return {
          errors: true,
          errorMessage: "You must agree to these terms",
        }
      }
    }
  }
  return result
}

function DonateForm() {
  const [formState, dispatch] = useReducer(donateFormReducer, initialState)
  const [errors, dispatchError] = useReducer(formErrorReducer, {})
  const handleSubmit = e => {
    e.preventDefault()
    const allFields = [
      "amount",
      "cause",
      "fellow",
      "isIndianCitizen",
      "pan",
      "passportNumber",
      "claimNoPassport",
      "firstName",
      "lastName",
      "email",
      "referralCode",
      "company",
      "address",
      "city",
      "state",
      "country",
      "postalCode",
      "phone",
      "terms",
    ]
    const formErrors = allFields.map(
      field => checkValidation(field, formState[field], formState).errors
    )
    const hasFormErrors = formErrors.some(v => v)
    if (!hasFormErrors) {
      const body = Object.fromEntries(
        Object.entries(formState).filter(([_, v]) => v !== "")
      )
      fetch(`${process.env.GATSBY_CROSSROADS_HOST}/public/donations`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(response => response.text())
        .then(htmlText => {
          const formDiv = document.createElement("div")
          formDiv.innerHTML = htmlText.trim()
          const form = formDiv.firstChild
          document.body.appendChild(form)
          form.submit()
        })
        .catch(console.log)
    }
  }

  const checkValidation = (field, value, state) => {
    const { errors, errorMessage } = validateField(field, value, state)
    if (errors) {
      dispatchError({ type: "setError", field, errorMessage })
    }
    return { errors, errorMessage }
  }

  const handleChange = useCallback(
    (e, k, v) => {
      const element = e.currentTarget
      let field, value
      if (k) {
        field = k
      } else {
        field = element.id
      }
      if (v) {
        value = v
      } else {
        if (element.type === "checkbox") value = element.checked
        else value = element.value
      }
      dispatch({ field, value })
      dispatchError({ type: "clearError", field })
    },
    [dispatch]
  )
  const handleBlur = useCallback(
    e => {
      const { id, value } = e.currentTarget
      checkValidation(id, value, formState)
    },
    [dispatchError, checkValidation, formState]
  )
  return (
    <Form noValidate className="donate-form" onSubmit={handleSubmit}>
      <FormField
        label={`Amount in ${formState.currency}`}
        name="amount"
        required
        errorMessage={errors.amount}
      >
        <ButtonGroup toggle className="mb-2">
          {presetAmounts.map(amt => (
            <ToggleButton
              key={amt}
              type="radio"
              variant="secondary"
              name={`amount-${amt}`}
              value={amt}
              checked={formState.amount === amt}
              onChange={e => {
                handleChange(e, "amount", amt)
              }}
            >
              {amt.toLocaleString("en-IN")}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <InputGroup hasValidation>
          <InputGroup.Prepend>
            <InputGroup.Text>₹</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            min={100}
            value={formState.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={errors?.amount}
          />
        </InputGroup>
      </FormField>

      <FormField
        label="Where would you like your money to be used?"
        name="cause"
        errorMessage={errors.cause}
        required
      >
        <ButtonGroup toggle>
          {causes.map(cause => (
            <ToggleButton
              key={cause}
              type="radio"
              variant="secondary"
              name={`cause-${cause}`}
              value={cause}
              checked={formState.cause === cause}
              onChange={e => {
                handleChange(e, "cause")
              }}
            >
              {cause}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </FormField>

      {formState.cause === "Specific Fellow Project" && (
        <FormField
          label="Fellow Name/Email"
          name="fellow"
          errorMessage={errors.fellow}
          required
        >
          <Form.Control
            type="text"
            value={formState.fellow}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={errors?.fellow}
          />
        </FormField>
      )}

      <FormField
        label="Are you an Indian Citizen?"
        name="isIndianCitizen"
        required
        errorMessage={errors.isIndianCitizen}
      >
        <ButtonGroup toggle>
          {["Yes", "No"].map(option => (
            <ToggleButton
              key={option}
              type="radio"
              variant="secondary"
              name={`ctzn-${option}`}
              value={option}
              checked={formState.isIndianCitizen === option}
              onChange={e => handleChange(e, "isIndianCitizen")}
            >
              {option}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </FormField>

      {formState.isIndianCitizen === "Yes" && (
        <FormField
          label="PAN Card Number"
          name="pan"
          required
          errorMessage={errors.pan}
          helpText="Your Donation will be eligible for exemption under section 80G of the Income Tax Act, 1961"
        >
          <Form.Control
            type="text"
            value={formState.pan}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={errors?.pan}
            pattern={panRegex}
          />
        </FormField>
      )}
      {formState.isIndianCitizen !== "Yes" && (
        <>
          <FormField
            label="Passport Number"
            name="passportNumber"
            required
            errorMessage={errors.passportNumber}
          >
            <Form.Control
              id="passportNumber"
              type="text"
              required
              value={formState.passportNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={errors?.passportNumber}
              disabled={formState.claimNoPassport}
            />
          </FormField>
          <Form.Group as={Row}>
            <Col sm={{ span: 8, offset: 4 }}>
              <Form.Check
                type="checkbox"
                label="I don't have a passport"
                id="claimNoPassport"
                checked={formState.claimNoPassport}
                onChange={e => {
                  dispatchError({ type: "clearError", field: "passportNumber" })
                  handleChange(e)
                }}
                onBlur={handleBlur}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <hr />

      <FormField
        label="First Name"
        name="firstName"
        required
        errorMessage={errors.firstName}
      >
        <Form.Control
          type="text"
          value={formState.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.firstName}
        />
      </FormField>
      <FormField
        label="Last Name"
        name="lastName"
        errorMessage={errors.lastName}
      >
        <Form.Control
          type="text"
          value={formState.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors.lastName}
        />
      </FormField>
      <FormField
        label="Email"
        name="email"
        required
        errorMessage={errors.email}
      >
        <Form.Control
          required
          type="email"
          value={formState.email}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.email}
        />
      </FormField>
      <FormField
        label="Referral Code"
        name="referralCode"
        errorMessage={errors.referralCode}
        helpText="If you were referred by someone to donate, and they shared a referral code with you, please enter it here."
      >
        <Form.Control
          type="text"
          value={formState.referralCode}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.referralCode}
        />
      </FormField>
      <FormField
        label="Organisation"
        name="company"
        errorMessage={errors.company}
      >
        <Form.Control
          type="text"
          value={formState.company}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.company}
        />
      </FormField>
      <FormField
        label="Address"
        name="address"
        required
        errorMessage={errors.address}
      >
        <Form.Control
          as="textarea"
          value={formState.address}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.address}
        />
      </FormField>

      <FormField label="City" name="city" required errorMessage={errors.city}>
        <Form.Control
          type="text"
          value={formState.city}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.city}
        />
      </FormField>

      <FormField
        label="State"
        name="state"
        required
        errorMessage={errors.state}
      >
        <Form.Control
          type="text"
          value={formState.state}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.state}
        />
      </FormField>

      <FormField
        label="Country"
        name="country"
        required
        errorMessage={errors.country}
      >
        <Form.Control
          as="select"
          value={formState.country}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.country}
        >
          {countries.map(country => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Form.Control>
      </FormField>

      <FormField
        label="Postal Code"
        name="postalCode"
        required
        errorMessage={errors.postalCode}
      >
        <Form.Control
          type="text"
          value={formState.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.postalCode}
        />
      </FormField>
      <FormField
        label="Phone"
        name="phone"
        required
        errorMessage={errors.phone}
      >
        <Form.Control
          type="text"
          value={formState.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={errors?.phone}
        />
      </FormField>

      <Form.Group as={Row}>
        <Col sm={12}>
          <Form.Check
            type="checkbox"
            id="terms"
            label="I have read through the website's Privacy Policy and agree to make a donation. I confirm that the information given in this form is true, complete and accurate."
            checked={formState.terms}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={errors?.terms}
            feedback={errors.terms}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Col sm={{ span: 4, offset: 4 }}>
          <Button
            variant="primary"
            as="input"
            type="submit"
            value="Submit"
            block
          />
        </Col>
      </Form.Group>
    </Form>
  )
}

export default DonateForm

function FormField({
  name,
  label,
  required,
  children,
  errorMessage,
  helpText,
}) {
  return (
    <Form.Group as={Row} controlId={name}>
      <Form.Label column sm={4} className={required ? "required" : null}>
        {label}
      </Form.Label>
      <Col sm={8}>
        {React.Children.count(children) === 1
          ? React.cloneElement(children, { required })
          : children}
        {helpText && <Form.Text>{helpText}</Form.Text>}
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}
FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,

  /** Cloned to children only if single child is passed */
  required: PropTypes.bool,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  helpText: PropTypes.string,
}
FormField.defaultProps = {
  required: false,
}
